<template>
	<div class="instantOrder">
		<el-card class="box-card father-card" shadow="never">
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<el-form-item label="订单编号">
						<el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item label="机构">
						<el-select  v-model="form.region" placeholder="请选择" name="1" @change="scompany(form.region)"
							clearable>
							<el-option :label="regionItem.companyName" :value="regionItem.uuid"
								v-for="(regionItem,index) in orderCompanyList" :key="index">
							</el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="车辆">
                        <el-select filterable v-model="form.carNum" placeholder="请先选择机构，再选择车辆" clearable>
                                <el-option
                                    v-for="(item, index) in carList"
                                    :key="index"
                                    :label="item.carNum"
                                    :value="item.carNum"
                                ></el-option>
                                </el-select>
                      </el-form-item>
                    <el-form-item label="订单开始结束时间">
                        <WTimer ref="WTimer" v-model="form" :startEnd="{start:'startOrderDate',end:'endOrderDate'}" />
                    </el-form-item>
					<!-- <el-form-item label="订单开始时间" prop="disinfectTime">
						<el-date-picker v-model="form.startOrderDate" type="date" placeholder="开始时间" clearable
							format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="到" prop="disinfectTime">
						<el-date-picker v-model="form.endOrderDate" type="date" placeholder="结束时间" clearable
							format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item> -->
                    <el-form-item label="状态">
						<el-select v-model="form.status" placeholder="请选择状态" clearable>
							<el-option v-for="(statusItme,index) in paymentStatusList" :key="index"
								:label="statusItme.text" :value="statusItme.status"></el-option>
						</el-select>
					</el-form-item>
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="success" icon="el-icon-search" @click="search(1)">搜索</el-button>
						<el-button type="primary" icon="el-icon-delete" @click="reset()">重置</el-button>
						<el-button type="info" icon="el-icon-share" @click="toExcel()">导出</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
		<el-table-column prop="orderDate" label="订单时间" align="center"></el-table-column>
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="firstPayNo" label="预付费支付流水号" align="center"></el-table-column>
					<el-table-column prop="firstPayChannel" label="预付费支付渠道" align="center"></el-table-column>
					<el-table-column prop="secondPayNo" label="结单费支付流水号" align="center"></el-table-column>
					<el-table-column prop="secondPayChannel" label="结单费支付渠道" align="center"></el-table-column>

					<el-table-column prop="baseMoney" label="基础费用" align="center"></el-table-column>
                     <el-table-column prop="transportMoney" label="转运费" align="center"></el-table-column>
					<el-table-column prop="carryMoney" label="搬抬费" align="center"></el-table-column>
					<el-table-column prop="medicineMoney" label="用药费用" align="center"></el-table-column>
					<el-table-column prop="medicalsetMoney" label="设备费用" align="center"></el-table-column>
                    <el-table-column prop="mediMoney" label="用药加设备费" align="center"></el-table-column>
					<el-table-column prop="otherMoney" label="其他费用" align="center"></el-table-column>
					<el-table-column prop="onePredictMoney" label="预付费" align="center">
						<template slot-scope="scope">
							{{scope.row.onePredictMoney || 0}}
						</template>
					</el-table-column>
                    <el-table-column prop="predictMoney" label="结单费" align="center"></el-table-column>
                    <el-table-column prop="surcharge" label="补充收款" align="center"></el-table-column>
					<el-table-column prop="refundMoney1" label="预付费退款" align="center"></el-table-column>
					<el-table-column prop="refundMoney2" label="结单费退款" align="center"></el-table-column>

                    <el-table-column prop="sumPayFee" label="实付总付费" align="center">
						<template slot-scope="scope">
							{{scope.row.sumPayFee.toFixed(2) || 0}}
						</template>
					</el-table-column>
                    <el-table-column prop="bargainStatus" label="是否议价" align="center"></el-table-column>
					<!-- <el-table-column prop="bargainStatus" label="是否议价" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.bargainStatus === '0'">{{ "不议价" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.bargainStatus === '1'">{{ "议价" }}
							</el-tag>
						</template>
					</el-table-column> -->
                    <el-table-column prop="companyName" label="机构名字" align="center"></el-table-column>
                    <el-table-column
                        prop="applicationCode"
                        label="订单来源"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span
                                v-if="scope.row.applicationCode === '10001'"
                                >{{ '小程序' }}
                            </span>
                            <span
                                v-else-if="scope.row.applicationCode === '10000'"
                                >{{ '平台' }}
                            </span>
                            <span
                                v-else-if="scope.row.applicationCode === '10002'"
                                >{{ 'APP' }}
                            </span>
                            <span
                                v-else-if="scope.row.applicationCode === '10003'"
                                >{{ '健康卡' }}
                            </span>
                            <span
                                v-else-if="scope.row.applicationCode === '10008'"
                                >{{ '公众号H5' }}
                            </span>
                            <span   v-else>{{
                                '优医指南'
                            }}</span>
                        </template>
                    </el-table-column>
					<el-table-column prop="userName" label="用户姓名" align="center"></el-table-column>
					<el-table-column prop="userPhone" label="用户电话" align="center"></el-table-column>
					<el-table-column prop="carNum" label="车牌号码" align="center"></el-table-column>
					<el-table-column prop="driverName" label="司机名称" align="center"></el-table-column>

					<el-table-column show-overflow-tooltip prop="remark" label="备注" align="center"></el-table-column>
					<el-table-column prop="orderFactMile" label="实跑公里数" align="center"></el-table-column>
					<el-table-column show-overflow-tooltip prop="startAddress" label="起始地" align="center"></el-table-column>
					<el-table-column show-overflow-tooltip prop="endAddress" label="目的地" align="center"></el-table-column>
                    <el-table-column prop="startHospName" label="出发医院" align="center"></el-table-column>
                    <el-table-column prop="startDepartment" label="科室" align="center"></el-table-column>
                    <el-table-column prop="startBed" label="床位" align="center"></el-table-column>
                    <el-table-column prop="salesman" label="业务员" align="center"></el-table-column>

                    <el-table-column prop="status" label="状态" align="center">
						<template slot-scope="scope">
							<span effect="dark" v-if="scope.row.status === '0'">{{ "新增" }}</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '1'">{{ "派单" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '2'">{{ "接单" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '3'">{{ "变更" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '4'">{{ "已支付" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '5'">{{ "取消订单" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '6'">{{ "已退款" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '7'">{{ "完成" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '8'">{{ "代付预付费" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '9'">{{ "到达出发地" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '10'">{{ "到达目的地" }}
							</span>
							<span type="info" effect="dark" v-else>
								{{
                "不正常完成"
                }}
							</span>
						</template>
					</el-table-column>
					<!-- orderType -->
					<el-table-column prop="transportTypeName" label="转运类型" align="center"></el-table-column>
					<!-- <el-table-column label="详情" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
            </template>
          </el-table-column> -->
				</el-table>
				<!-- 分页区域 -->
				<el-pagination @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.PageNo" :page-sizes="[5, 10, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				<!-- 订单详情弹出框 -->
				<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
					<span slot="title" class="dialog-Title">即时订单详情</span>
					<el-form :model="orderForm" inline ref="orderForm">
						<el-row class="subtitle">客户资料</el-row>
						<!-- 第一行 -->
						<el-row>
							<el-form-item label="客户姓名">
								<el-input v-model="orderForm.memberName" autocomplete="off" disabled clearable>
								</el-input>
							</el-form-item>
							<el-form-item label="联系电话">
								<el-input v-model="orderForm.memberMobile" autocomplete="off" disabled clearable>
								</el-input>
							</el-form-item>
						</el-row>
						<el-row class="subtitle">车辆信息</el-row>
						<!-- 第二行 -->
						<el-row>
							<el-form-item label="车牌号">
								<el-select v-model="orderForm.carNum" placeholder="请选择车牌号" filterable clearable
									:disabled="isDisable">
									<el-option :label="item.carNum" :value="item.carNum"
										v-for="(item, index) in carNumber" :key="index"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="转运类型">
								<el-input v-model="orderForm.transferVehicle" autocomplete="off" :disabled="isDisable"
									clearable></el-input>
							</el-form-item>
						</el-row>
						<!-- 第三行 -->
						<el-row>
							<el-form-item label="司机名称">
								<el-select v-model="orderForm.driverName" placeholder="请选择司机名称" filterable clearable
									:disabled="isDisable">
									<el-option :label="item.driverName" :value="item.uuid"
										v-for="(item, index) in driverNumber" :key="index"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="司机电话">
								<el-input v-model="orderForm.driverPhone" autocomplete="off" :disabled="isDisable"
									clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"></el-input>
							</el-form-item>
						</el-row>
						<!-- 第四行 -->
						<el-row>
							<el-form-item label="随行医生">
								<el-select v-model="orderForm.doctorList" placeholder="请选择随车医生" filterable clearable
									multiple :disabled="isDisable">
									<el-option :label="item.docName" :value="item.uuid" v-for="(item, index) in doctor"
										:key="index"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="随行护士">
								<el-select v-model="orderForm.nurseList" placeholder="请选择随车护士" filterable clearable
									multiple :disabled="isDisable">
									<el-option :label="item.docName" :value="item.uuid" v-for="(item, index) in nurse"
										:key="index"></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<!-- 第五行 -->
						<el-row>
							<el-form-item label="护送人员">
								<el-select v-model="orderForm.escortList" placeholder="请选择护送人员" filterable clearable
									multiple :disabled="isDisable">
									<el-option :label="item.docName" :value="item.uuid" v-for="(item, index) in escorts"
										:key="index"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="搬抬人员">
								<el-select v-model="orderForm.raiseList" placeholder="请选择搬抬人员" filterable clearable
									multiple :disabled="isDisable">
									<el-option :label="item.docName" :value="item.uuid" v-for="(item, index) in lifting"
										:key="index"></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<!-- 第六行 -->
						<el-row>
							<el-form-item label="出发地">
								<el-input v-model="orderForm.startAddress" autocomplete="off" :disabled="isDisable"
									clearable></el-input>
							</el-form-item>
						</el-row>
						<!-- 第七行 -->
						<el-row>
							<el-form-item label="目的地">
								<el-input v-model="orderForm.endAddress" autocomplete="off" :disabled="isDisable"
									clearable></el-input>
							</el-form-item>
						</el-row>
						<!-- 第八行 -->
						<el-row>
							<el-form-item label="病人姓名">
								<el-input v-model="orderForm.memberName" autocomplete="off" disabled clearable>
								</el-input>
							</el-form-item>
							<el-form-item label="手机号码">
								<el-input v-model="orderForm.memberMobile" autocomplete="off" disabled clearable>
								</el-input>
							</el-form-item>
						</el-row>
						<!-- 第九行 -->
						<el-row>
							<el-form-item label="病情描述">
								<el-input v-model="orderForm.patientConditionRemark" autocomplete="off"
									:disabled="isDisable" clearable></el-input>
							</el-form-item>
						</el-row>
						<el-row class="subtitle">订单信息</el-row>
						<!-- 第十行 -->
						<el-row>
							<el-form-item label="订单编号">
								<el-input v-model="orderForm.orderNo" autocomplete="off" disabled></el-input>
							</el-form-item>
							<el-form-item label="预付金额">
								<el-input v-model="orderForm.orderPredictPay" autocomplete="off" disabled></el-input>
							</el-form-item>
							<el-form-item label="实付金额">
								<el-input v-model="orderForm.orderFactFee" autocomplete="off" disabled></el-input>
							</el-form-item>
						</el-row>
						<!-- 第十一行 -->
						<el-row>
							<el-form-item label="预估金额">
								<el-input v-model="orderForm.orderPredictFee" autocomplete="off" disabled></el-input>
							</el-form-item>
							<el-form-item label="订单来源">
								<el-input
									v-model="orderForm.applicationCode == 10000 ? '综合业务平台' : orderForm.applicationCode == 10001 ? '小程序':'APP'"
									autocomplete="off" disabled clearable
									:label="orderForm.applicationCode == 10000 ? '综合业务平台' : orderForm.applicationCode == 10001 ? '小程序':'APP'">
								</el-input>
							</el-form-item>
							<el-form-item label="下单时间">
								<el-input v-model="orderForm.orderDate" autocomplete="off" disabled></el-input>
							</el-form-item>
						</el-row>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
					</div>
				</el-dialog>
			</el-row>
		</el-card>
	</div>
</template>

<script>
import store from '@/store/index.js'
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'selectOrder',
  components: {
    WTimer
  },
  data () {
    return {
      comName: '',
      List: [], // 表格数据源
      orderCompanyList: [], // 机构列表
      paymentStatusList: [
        {
          status: 0,
          text: '新增'
        }, // 支付状态列表
        {
          status: 1,
          text: '派单'
        },
        {
          status: 2,
          text: '已接单'
        },
        // {
        // 	status: 3,
        // 	text: '变更'
        // },
        // {
        // 	status: 4,
        // 	text: '已支付'
        // },
        {
          status: 5,
          text: '取消订单'
        },
        {
          status: 6,
          text: '全额退款'
        },
        {
          status: 7,
          text: '完成'
        },
        {
          status: 8,
          text: '待付预付费'
        },
        {
          status: 9,
          text: '到达出发地'
        },
        {
          status: 10,
          text: '到达目的地'
        },
        {
          status: 11,
          text: '非正常完成'
        },
        {
          status: 12,
          text: '预付退款'
        },
        {
          status: 13,
          text: '结单退款'
        }
      ],
      //   paymentStatusList: [
      //     // {
      //     // 	status: 4,
      //     // 	text: '已支付'
      //     // },
      //     // {
      //     // 	status: 6,
      //     // 	text: '已退款'
      //     // },
      //     {
      //       status: 7,
      //       text: '完成'
      //     },
      //     {
      //       status: 11,
      //       text: '非正常完成'
      //     }
      //     // {
      //     // 	status: 8,
      //     // 	text: '待付预付费'
      //     // }
      //   ],
      form: {
        orderType: '',
        status: '',
        region: '',
        startOrderDate: '',
        endOrderDate: '',
        carNum: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      selectedWorkName: {
        companyName: ''
      },
      fileName: '',
      isDisable: true,
      carNumber: [],
      driverNumber: [],
      doctor: [],
      escorts: [],
      lifting: [],
      nurse: [],
      carList: []
    }
  },
  created () {
    // this.getOrderList()
    this.getorderCompany()
    this.search()
    // this.getcarList()
  },
  methods: {
    /**
     * 选择机构后，在请求车辆
     */
    mechanismChange (value) {
      this.form.carNum = ''
      if (value) {
        this.getcarList(value)
      } else {
        this.carList = []// 说明除去了机构，则车辆列表清空
      }
    },
    /**
     * 获取车辆列表
     */
    async getcarList (value) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleList', {
        pageNo: 1,
        pageSize: 1000,
        corpId: value
      })
      this.carList = res.data
      console.log(res.data, '获取车辆列表')
    },
    async scompany (id) {
      this.form.carNum = ''
      if (id) {
        this.getcarList(id)
      } else {
        this.carList = []// 说明除去了机构，则车辆列表清空
      }
    },
    // 换页码时触发
    async handlePageNumChange (PageNum) {
      console.log(PageNum)
      this.paging.PageNo = PageNum
      console.log(this.paging.PageNo, 'this.paging.PageNo')
      await this.search()
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
      console.log(this.orderCompanyList, '14141')
    },
    // 获取所有实时订单
    async getOrderList () {
      const {
        data: res
      } = await this.$http.post('/billService/tInfoOrderFee/getListByParamsForStatic', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
      })
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data.records
      this.total = +res.pageBean.pageDataCount
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getOrderList()
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    reset () {
      this.form.orderType = ''
      this.form.status = ''
      this.form.region = ''
      this.form.orderNo = ''
      this.form.startOrderDate = ''
      this.form.endOrderDate = ''
      this.selectedWorkName = ''
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.form.carNum = ''
      this.search()
    },
    // 导出Excel
    toExcel () {
      var date = new Date(this.form.startOrderDate)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      var daten = new Date(this.form.endOrderDate)
      var yn = daten.getFullYear()
      var mn = daten.getMonth() + 1
      mn = mn < 10 ? ('0' + mn) : mn
      var dn = daten.getDate()
      dn = dn < 10 ? ('0' + dn) : dn
      const endtime = yn + '-' + mn + '-' + dn
      if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate) {
        this.fileName = this.selectedWorkName.companyName + '查看订单汇表'
        this.exportExcel(this.fileName + time + '到' + endtime)
      } else if (this.selectedWorkName.companyName && this.form.startOrderDate == '' && this.form.endOrderDate ==
					'') {
        this.fileName = this.selectedWorkName.companyName + '查看订单汇表'
        this.exportExcel(this.fileName)
      } else if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate == '') {
        this.fileName = this.selectedWorkName.companyName + '查看订单汇表'
        this.exportExcel(this.fileName + time)
      } else if (this.selectedWorkName.companyName && this.form.endOrderDate && this.form.startOrderDate == '') {
        this.fileName = this.selectedWorkName.companyName + '查看订单汇表'
        this.exportExcel(this.fileName + endtime)
      } else {
        this.fileName = '查看订单总汇表'
        this.exportExcel(this.fileName)
      }
    },
    async exportExcel (value) {
      if (this.selectedWorkName.companyName) {
        this.comName = this.selectedWorkName.companyName
      } else {
        this.comName = '' // 全机构
      }
      const data = {
        // "status": this.form.status,
        // 'orderCompanyId': this.form.region,
        // "pageSize": this.paging.pageSize,
        // "companyName": this.comName,
        // "pageNo": this.paging.PageNo,
        // "orderNo": this.form.orderNo,
        // "startOrderDate": this.form.startOrderDate,
        // "endOrderDate": this.form.endOrderDate,
        status: this.form.status,
        orderType: this.form.orderType,
        companyId: this.form.region,
        companyName: this.comName,
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        startOrderDate: this.form.startOrderDate,
        endOrderDate: this.form.endOrderDate,
        carNum: this.form.carNum
      }
      // http://192.168.123.190:9000
      await this.$http.post('/pmService/tInfoOrderFee/toExcel', data, {
        responseType: 'blob'
      }).then(res => {
        console.log(res.data)
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        // 第一种
        // '订单汇表.xlsx'
        const fileName = value
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      })
    },
    // 点击搜索按钮触发
    async search (v) {
      // 判断有输入账号
      // if (this.selectedWorkName.companyName) {
      // 	this.comName = this.selectedWorkName.companyName
      // } else {
      // 	this.comName = ""
      // }
      // if (this.form.orderNo === '' && this.form.applicationCode === '') return this.$message.error('请输入搜索内容')
      // 调接口，进行查询
      console.log(v)
      if (v == 1) {
        this.paging.PageNo = 1
      }
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrderFee/getListByParamsByOrderDate', {
        headers: {
          AUTH_TYPE: 'orderQuery'
        },
        status: this.form.status,
        orderType: this.form.orderType,
        companyId: this.form.region,
        companyName: this.comName,
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        startOrderDate: this.form.startOrderDate,
        endOrderDate: this.form.endOrderDate,
        applicationCode: this.form.applicationCode,
        carNum: this.form.carNum
      })
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data.records
      console.log(res, 'resres')
      this.total = res.data.total
    },
    // 点击查看详情按钮触发
    look (v) {
      this.getAllCarNumber(v)
      this.getAlldriverNumber(v)
      this.getAlldoctor(v)
      this.getAllnurse(v)
      this.getAlllifting(v)
      this.getAllEscorts(v)
      this.orderForm = v
      this.orderDialogFormVisible = true
    },
    // 获取车牌号--调试完成
    async getAllCarNumber (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleList', {
        corpId: v.companyId
      })
      console.log(res.data)
      if (res.result !== 'true') return this.$message.error('获取车牌号列表失败')
      this.carNumber = res.data
      // console.log(this.carNumber);
    },
    // 获取司机--调试完成
    async getAlldriverNumber (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDriver/getTInfoDriverList', {
        companyId: v.companyId
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取司机失败')
      this.driverNumber = res.data.records
    },
    // 获取随车医生--调试完成
    async getAlldoctor (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.companyId,
        docType: 0
      })
      if (res.statusCode !== '200') return this.$message.error('获取医生列表失败')
      this.doctor = res.data.records
    },
    // 获取随车护士--调试完成
    async getAllnurse (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.companyId,
        docType: 1
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取随车护士失败')
      this.nurse = res.data.records
    },
    // 获取所有搬抬人员--调试完成
    async getAlllifting (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.companyId,
        docType: 3
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取搬抬人员失败')
      this.lifting = res.data.records
      // console.log(res);
    },
    // 获取所有护送员--调试完成
    async getAllEscorts (v) {
      // console.log(this.allotForm.orderCompany);
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.companyId,
        docType: 2
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取护送员失败')
      this.escorts = res.data.records
      // console.log(res);
    }
  }

}
</script>
<style lang="less" scoped>
	.instantOrder {
		width: 100%;
		height: 100%;

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				/deep/ .el-table {
					margin-bottom: 20px;

					.el-table__row--striped td {
						background-color: #e4eaec !important;
					}

					.current-row>td {
						background-color: #66b1ff !important;
					}
				}

				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}

				.order-dialog {
					.dialog-Title {
						color: #0097fe;
						font-size: 20px;
						font-weight: 700;
					}

					/deep/ .el-form {
						.subtitle {
							color: #0097fe;
							font-size: 14px;
							font-weight: 700;
							// margin: 15px 0;
							margin-bottom: 20px;
							padding-left: 6px;
							border-left: 5px solid #0097fe;
						}

						.el-row {
							// flex: 1;
							display: flex;

							// justify-content: space-between;
							.el-form-item {
								// width: 50%;
								flex: 1;
								display: flex;

								// text-align: left;
								.el-form-item__content {
									flex: 1;

									.el-input {
										width: 100%;

										.el-input__inner {
											width: 100%;
										}
									}

									.el-select {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
