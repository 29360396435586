<template>
  <div>
    <div v-if="type==1">
      <el-date-picker v-model="Times"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      @change="getTime"
                      :picker-options="pickerOptions">
      </el-date-picker>
    </div>
    <div v-else>
      <el-date-picker v-model="Times"
                      type="datetimerange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd hh:mm:ss"
                      format="yyyy-MM-dd hh:mm:ss"
                      @change="getTime"
                      :picker-options="pickerOptions">
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import { pickerOptions } from '@/utils'
export default {
  model: {
    prop: 'form',
    event: 'itemChange'
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
    form: {
      type: Object
    },
    startEnd: {
      type: Object
    }
  },
  data () {
    return {
      pickerForm: this.form,
      Times: '',
      pickerOptions: pickerOptions

    }
  },
  methods: {
    getTime () {
      const { start, end } = this.startEnd
      if (this.Times && this.Times.length > 0) {
        this.$set(this.pickerForm, start, this.Times[0])
        this.$set(this.pickerForm, end, this.Times[1])
        // this.pickerForm[start] = this.Times[0]
        // this.pickerForm[end] = this.Times[1]
        this.$emit('itemChange', this.pickerForm)
      } else {
        this.resetFields()
      }
    },
    resetFields () {
      const { start, end } = this.startEnd
      this.Times = null
      this.pickerForm[start] = ''
      this.pickerForm[end] = ''
      this.$emit('itemChange', this.pickerForm)
    }

  }
}
</script>
<style scoped lang="less" >
/deep/ .el-range-editor.el-input__inner {
  width: auto !important;
}
</style>
