function isOrderCompany () {
  if (sessionStorage.getItem('order_company')) {
    return false
  } else {
    return true
  }
}
// 日期组件配置
const pickerOptions = {
  shortcuts: [
    {
      text: '今日',
      onClick (picker) {
        const end = new Date()
        const start = new Date(new Date().setHours(0, 0, 0, 0))
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '昨日',
      onClick (picker) {
        const start = new Date()
        start.setTime(start.getTime() - 24 * 60 * 60 * 1000)
        start.setHours(0, 0, 0, 0)
        const end = new Date()
        end.setTime(end.getTime() - 24 * 60 * 60 * 1000)
        end.setHours(23, 59, 59, 0)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近一周',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一个月',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近三个月',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}
export {
  isOrderCompany,
  pickerOptions
}
